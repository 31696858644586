.certificate {
    &__icon {
        display: inline-block;
        height: 2.438em;
        width: 1.813em;
        margin-right: 0.5em;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    &__content {
        font-weight: 900;
        font-size: 1rem;
        text-transform: uppercase;
    }
}
