.hero-video {
    height: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    transition: $transition-default;

    &__wrapper {
        background: map-deep-get($colors, "gray", "darker");
        opacity: 0.5;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__poster {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 0.5;
    }

    /* stylelint-disable */
    .video-js .vjs-tech {
        width: auto;
        min-width: 100%;
        height: auto;
        bottom: 0;
        min-height: 100%;
    }
    /* stylelint-enable */
}