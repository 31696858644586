.main {
    overflow: hidden;
    max-width: 100%;
    transition: $transition-default;
    flex: 1 1 100%;
    //height: 100%;
    padding-top: var(--hH);

    @include media-breakpoint-up(md) {
        padding-top: 0;
    }
}