.jobs-item {
    $block: &;
    align-items: center;
    border: .0625rem solid #f2f2fa;
    color: #454547;
    display: flex;
    font-weight: 400;
    gap: 1rem;
    justify-content: space-between;
    padding: 2rem;
    transition: $transition-default;
    width: 100%;

    &:hover {
        color: map-deep-get($colors, "brand", "primary");
        text-decoration: none;

        #{$block}__title {
            color: map-deep-get($colors, "brand", "primary");
        }

        #{$block}__button {
            background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
            color: map-deep-get($colors, "black", "default");
        }
    }

    &__info {
        align-items: center;
        display: flex;
        flex-flow: row wrap;
        gap: 1rem;
        width: 100%;

        @include media-breakpoint-up(md) {
            flex-flow: row wrap;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        h5 {
            line-height: .8;
            margin-top: 0;
        }

        p {
            font-size: 1rem;
            line-height: 1.5;
            margin-bottom: 0;
        }
    }

    &__title {
        color: map-deep-get($colors, "black", "default");
        flex: 1 0 100%;
        margin: .5rem 0 0;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            flex: 1 0 0;
        }
    }
}
