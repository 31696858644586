.jobs-list {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    &__row {
        border: .0625rem solid #F2F2FA;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: space-between;
        padding: 2rem;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            gap: 2rem;
        }
    }

    &__partial {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        width: 100%;

        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
    }

    &__image-wrapper {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        @include media-breakpoint-up(md) {
            justify-content: flex-start;
            width: fit-content;
        }
    }

    &__image {
        aspect-ratio: 1;
        filter: grayscale(1);
        height: 5.25rem;
        object-fit: contain;
        width: 5.875rem;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    &__title {
        color: black;
        line-height: .8;
    }

    &__paragraph {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        font-size: 1rem;
        line-height: 1.5;
        overflow: hidden;
    }

    &__link {
        align-items: center;
        color: black;
        display: flex;
    }

    &__button {
        display: flex;
        gap: 1rem;
        white-space: nowrap;

        img {
            height: .75rem;
            position: relative;
            top: -.125rem;
            width: 1.25rem;
        }
    }
}
