.pagination {
    margin-top: 3.125rem;

    &__list {
        align-items: center;
        display: flex;
        justify-content: center;
        gap: 1rem;
        width: 100%;
    }

    &__control-link {
        padding: 1.0625rem .8125rem 1rem;

        &--inactive {
            pointer-events: none;
            background-color: #F2F2FA;
        }

        &--rotated {
            padding: 1.0625rem .8125rem 1rem;
            transform: rotate(180deg);
        }
    }

    &__list-item {
        display: flex;
        gap: .5rem  ;
    }

    &__list-link {
        border-radius: 50%;
        color: map-deep-get($colors, 'black', 'default');
        padding: .375rem .8125rem;

        &--active {
            background-color: map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, 'white', 'default');
        }
    }
}
