.contact-form {
    -webkit-font-smoothing: antialiased;
    background: map-deep-get($colors, "black", "default");
    color: map-deep-get($colors, "white", "default");
    min-height: 100%;
    padding: 25px 25px 20px;

    @include media-breakpoint-up(md) {
        padding: 50px 50px 40px;
    }

    @include media-breakpoint-up(xl) {
        padding: 50px 65px;
    }

    // form builder
    /* stylelint-disable */
    input,
    textarea {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");
    }
    /* stylelint-enable */

    &__line {
        border-top: 1px dashed map-deep-get($colors, "brand", "primary");
        margin-bottom: rem-calc(24);
    }

    &__header {
        display: flex;
        align-items: start;
        width: 100%;
        margin-bottom: 1.5em;

        @include media-breakpoint-up(lg) {
            align-items: center;
        }
    }

    &__subtitle {
        margin-bottom: 0;
    }

    &__header-icon {
        margin-right: 1em;
        @include media-breakpoint-up(md) {
            margin-right: 2em;
        }

        svg {
            display: block;
            width: 2em;
            fill: map-deep-get($colors, "brand", "primary");

            @include media-breakpoint-up(md) {
                width: 4em;
            }

            @include media-breakpoint-up(lg) {
                width: 4.89em;
            }
        }
    }

    &__header-text {
        margin-bottom: 0;
    }
}