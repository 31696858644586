.hero-slider-wrapper {
    height: 80vh;
    margin-bottom: -20vh;
    position: relative;
    width: 80vw;

    @include media-breakpoint-up(sm) {
        height: 100vh;
        margin-bottom: -10px;
    }

    @include media-breakpoint-up(md) {
        height: 90vh;
        margin-bottom: -20vh;
        max-width: 60vw;
        width: 100vh;
    }

    @include media-breakpoint-up(lg) {
        max-width: 45vw;
        max-height: 75vw;
        width: 60vh;
    }

    @include media-breakpoint-up(xl) {
        max-height: 75vw;
    }

    @include media-breakpoint-up(xxl) {
        height: 80vh;
        margin-bottom: -20vh;
        max-width: 45vw;
        width: 60vh;
    }
}