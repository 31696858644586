﻿/* stylelint-disable */
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    color: map-deep-get($colors, "brand", "secondary");
    margin: 0 0 .5rem;
    font-family: $font-family-special;
    font-weight: 900;
    -webkit-hyphens: auto;
    hyphens: auto;
    letter-spacing: .05em;
    line-height: 1;
    text-transform: uppercase;

    em {
        color: map-deep-get($colors, "brand", "primary");
        font-style: normal;
    }
}
/* stylelint-enable */

p {
    margin-top: 0;
}

.page-header {
    margin-bottom: 0.2em;
}

h1, .h1 {
    font-size: 3.5rem;

    @include media-breakpoint-up(md) {
        font-size: 5rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 7rem;
    }
}

h2, .h2 {
    font-size: 2rem;

    @include media-breakpoint-up(md) {
        font-size: 3rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 5rem;
    }
}

h3, .h3 {
    font-size: 1.75rem;

    @include media-breakpoint-up(md) {
        font-size: 2.5rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 3rem;
    }
}

h4, .h4 {
    font-size: 1.5rem;

    @include media-breakpoint-up(md) {
        font-size: 2rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 2.5rem;
    }
}

h5, .h5 {
    font-size: 1.25rem;

    @include media-breakpoint-up(md) {
        font-size: 1.5rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 2rem;
    }
}

h6, .h6 {
    font-family: $font-family-base;
    font-size: 1.125rem;
    font-weight: 400;

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 1.5em;
    }
}

strong {
    font-weight: 900;
}

.intro {
    font-family: $font-family-base;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: normal;

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
    }

    @include media-breakpoint-up(xl) {
        font-size: 1.625rem;
    }
}

.sub-title {
    color: #454547;
    line-height: 1.2;
    opacity: .7;
}

.heading {
    &--primary {
        color: map-deep-get($colors, "brand", "primary");

        &.heading--bg {
            display: inline;
            background: rgba(map-deep-get($colors, "white", "default"), .8);
        }
    }

    &--white {
        color: map-deep-get($colors, "white", "default");

        &.heading--bg {
            display: inline;
            background: rgba(map-deep-get($colors, "brand", "primary"), .8);
        }
    }
}
