.job-media {
    position: relative;
    margin-bottom: 50px;
    margin-top: 50px;
    transition: $transition-default;
    $elm: &;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }

    &__poster {
        background: map-deep-get($colors, "brand", "primary");
        padding: 15vw 0;
        text-align: center;

        @include media-breakpoint-up(md) {
            padding: 20px;
        }

        @include media-breakpoint-up(xxl) {
            padding: 55px 60px 25px;
        }
    }

    &__poster-content {
        position: relative;
    }

    &__poster-title {
        color: map-deep-get($colors, "black", "default");
        line-height: 0.81;
    }

    &__poster-intro {
        color: map-deep-get($colors, "white", "default");
        text-decoration: underline;
    }

    &__image-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    &__image {
        height: 100%;
        object-fit: cover;
        opacity: 0.3;
        transition: $transition-default;
        width: 100%;
    }

    &__video-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding-top: 56.25%;
        width: 100%;
    }

    &__video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;

        &.vjs-playing + #{$elm}__video-trigger {
            opacity: 0;
            pointer-events: none;
        }

        /* stylelint-disable */
        .vjs-tech {
            width: 100%;
            height: 100%;
        }
        /* stylelint-enable */

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                + #{$elm}__video-trigger svg {
                    fill: map-deep-get($colors, "brand", "primary");
                }
            }
        }
    }

    &__video-trigger {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        appearance: none;
        width: 100%;
        position: relative;
        z-index: 10;
        outline: none;

        &:focus {
            outline: none;
        }
    }

    &--aspect-video {
        //padding-top: aspect-ratio(16, 9);

        #{$elm}__trigger {
            opacity: 0;
            pointer-events: none;
        }

        #{$elm}__video {
            z-index: 12;
            opacity: 1;
        }
    }

    @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
        &:hover {
            #{$elm}__image {
                opacity: 0.5;
            }
        }
    }
}
