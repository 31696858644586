.contact-person {
    background: map-deep-get($colors, "black", "default");
    color: map-deep-get($colors, "white", "default");
    width: 100%;
    padding-bottom: 20px;

    &__top {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
            display: block;
            margin-bottom: 0;
        }
    }

    &__content {
        width: 66.666%;
        flex: 1;
        padding: 20px 20px 0;

        @include media-breakpoint-up(md) {
            padding: 40px 40px 40px 0;
            width: 100%;
        }
    }

    &__image-wrapper {
        align-items: flex-end;
        width: 33.333%;
        height: auto;
        background: map-deep-get($colors, "gray", "light");
        display: flex;

        @include media-breakpoint-up(md) {
            width: 100%;
            display: block;
        }
    }

    &__image {
        min-height: 100%;
        max-width: 100%;
        object-fit: cover;
    }

    &__text {
        margin-bottom: 0;
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
    }

    &__list {
        padding: 20px;

        @include media-breakpoint-up(md) {
            padding: 0;
        }
    }

    &__list-item {
        margin-bottom: 1.25em;
    }

    &__links-item {
        color: map-deep-get($colors, "white", "default");
        white-space: nowrap;
        text-decoration: underline;
        display: block;
        padding-left: 2em;
        position: relative;
    }

    &__link {
        white-space: normal;
        hyphens: auto;
        display: inline-block;
        padding-right: 10px;

        &--email{
            hyphens: none;
            word-break: break-all;
        }
    }

    &__icon {
        height: 1.2rem;
        width: 1.2rem;
        display: inline-block;
        position: absolute;
        left: 0;
        top: 0;
        margin-top: -0.15em;

        @include media-breakpoint-up(xl) {
            height: 1.5rem;
            width: 1.5rem;
        }

        svg {
            height: 100%;
            fill: map-deep-get($colors, "brand", "primary");
            width: 100%;
        }
    }
}
