.hero-block {
    display: flex;
    position: relative;
    $block: &;

    &__background-wrapper {
        background-color: map-deep-get($colors, "black", "default");
        border-radius: $border-radius;
        margin: 160px 0 120px;
        padding-top: 56.72%;
        position: relative;
        width: 100%;
    }

    &__background {
        background: no-repeat center center;
        background-size: cover;
        height: 100%;
        transition: opacity $transition-default-smoothing $transition-default-time;
        width: 100%;

        // b-lazy
        /* stylelint-disable */
        &.b-lazy.b-loaded {
            opacity: 0.3;
        }
        /* stylelint-enable */
    }

    &__content,
    &__background,
    &__overlay,
    &__image-wrapper {
        border-radius: $border-radius;
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__image-wrapper {
        text-align: center;
    }

    &__image {
        display: inline-block;
        height: 100%;
        transition: opacity $transition-default-smoothing $transition-default-time;
        width: auto;
    }

    &__content {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        transition: opacity $transition-default-smoothing $transition-default-time;
        z-index: 10;

        &--hover {
            background: rgba(map-deep-get($colors, "brand", "primary"), 0.7);
            opacity: 0;
        }
    }

    &__title {
        color: map-deep-get($colors, "white", "default");
        display: block;
        margin-bottom: 0.5rem;
        padding: 0 5%;
        position: relative;
        text-shadow: 0 .25rem 2rem rgba(0, 0, 0, .55);

        @include media-breakpoint-up(xl) {
            font-size: 5.375rem;
        }

        &--black {
            color: map-deep-get($colors, "black", "default");
        }
    }

    &__subtitle {
        color: map-deep-get($colors, "white", "default");
        display: block;
        line-height: 1.2;
        padding: 0 10%;
        text-decoration: underline;

        @include media-breakpoint-up(xl) {
            font-size: 1.875rem;
        }
    }

    &--hover:not(:hover),
    &:hover {
        #{$block + "__image"},
        #{$block + "__content"} {
            opacity: 0;
        }

        #{$block + "__background"},
        #{$block + "__content--hover"} {
            opacity: 1;
        }
    }
}
