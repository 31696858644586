/* stylelint-disable */
$v-screen-sm: 350px;
$v-screen-md: 550px;
$v-screen-lg: 650px;
.v {
    &-flex {
        display: flex !important;
    }

    &-block {
        display: block !important;
    }

    &-hidden {
        display: none !important;
    }

    &-sm {
        @media (max-height: $v-screen-sm) {
            &-flex {
                display: flex !important;
            }
            &-block {
                display: block !important;
            }
            &-hidden {
                display: none !important;
            }
        }
    }

    &-md {
        @media (min-height: $v-screen-sm) and (max-height: $v-screen-md) {
            &-flex {
                display: flex !important;
            }
            &-block {
                display: block !important;
            }
            &-hidden {
                display: none !important;
            }
        }
    }

    &-lg {
        @media (min-height: $v-screen-lg) {
            &-flex {
                display: flex !important;
            }
            &-block {
                display: block !important;
            }
            &-hidden {
                display: none !important;
            }
        }
    }
}
/* stylelint-enable */
