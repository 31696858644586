.slider {
    $block: &;
    aspect-ratio: 3/2;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
        aspect-ratio: 1248/529;
    }

    &--fit-content,
    &--centered {
        /* stylelint-disable */
        .flickity-viewport {
            overflow: visible;
        }
        /* stylelint-enable */
    }

    &__wrapper {
        height: 100%;
        position: relative;
    }

    &__slides {
        height: inherit;
        overflow: hidden;
    }

    &__slide {
        bottom: 0;
        cursor: pointer;
        height: 100%;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        margin-right: 2rem;

        @include media-breakpoint-up(md) {
            width: 66%;
        }
    }

    &__content {
        bottom: 0;
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-end;
        height: 100%;
        left: 0;
        padding: 1rem;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 1;

        @include media-breakpoint-up(sm) {
            padding: 1.5rem;
        }

        @include media-breakpoint-up(md) {
            padding: 2rem;
        }

        @include media-breakpoint-up(lg) {
            padding: 4rem;
        }

        @include media-breakpoint-up(xl) {
            padding: 4rem 0 4rem 4rem;
            width: 50%;
        }
    }

    &__title {
        h2 {
            color: map-deep-get($colors, "white", "default");
            line-height: .85;

            @include media-breakpoint-up(xl) {
                font-size: 3.75rem;
            }
        }
    }

    &__description {
        p {
            color: map-deep-get($colors, "white", "default");
            font-size: 1rem;
            line-height: 1.5;

            @include media-breakpoint-up(sm) {
                font-size: 1.25rem;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__image-wrapper {
        border-radius: .25rem;
        height: inherit;
        overflow: hidden;
        position: relative;

        &::before {
            background: linear-gradient(0, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4));
            bottom: 0;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            z-index: 1;
        }
    }

    &__image {
        border-radius: .25rem;
        height: 100%;
        object-fit: cover;
        width: 100%;
    }

    &__nav {
        display: flex;
        gap: 0 .5rem;
        margin-top: 1rem;
        pointer-events: none;
        position: absolute;
        top: 100%;
        width: 100%;
        z-index: 1;

        @include media-breakpoint-up(lg) {
            justify-content: space-between;
            margin-top: 0;
            padding: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__nav-button {
        $el: &;
        border-radius: 50%;
        pointer-events: auto;
        padding: .5rem;

        @include media-breakpoint-up(sm) {
            padding: .75rem;
        }

        @media (min-width: 1440px) {
            // Custom breakpoint
            padding: 1.125rem;
        }

        &--prev {
            @media (min-width: 1440px) {
                // Custom breakpoint
                transform: translateX(-50%);
            }
        }

        &--next {
            @media (min-width: 1440px) {
                // Custom breakpoint
                transform: translateX(50%);
            }

            svg {
                transform: rotate(180deg);
            }
        }

        svg {
            fill: transparent;
            height: 1rem;
            width: 1rem;
            margin: 0;

            @include media-breakpoint-up(sm) {
                height: 1.25rem;
                width: 1.25rem;
            }

            @include media-breakpoint-up(lg) {
                height: 1.5rem;
                width: 1.5rem;
            }
        }
    }
}
