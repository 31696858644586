// Rich text styling layout
/* stylelint-disable */
.rich-text-area {
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: $line-height-base;

    @include media-breakpoint-up(md) {
        font-size: 1.25rem;
    }

    .intro {
        @extend .intro;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a:not(.button) {
        text-decoration: underline;
    }

    ul {
        margin: 1.25rem 0 1.25rem 0;

        li {
            line-height: 1.5;
            margin-bottom: .25rem;
            margin-top: .25rem;
            padding-left: 1.25rem;
            position: relative;

            &::before {
                background: map-deep-get($colors, "brand", "primary");
                border-radius: 50%;
                content: "";
                height: .375rem;
                left: 0;
                position: absolute;
                top: .75rem;
                transform: translateY(-50%);
                width: .375rem;

                @include media-breakpoint-up(md) {
                    top: .875rem;
                }
            }
        }

        ul {
            margin: 0;
        }
    }
}
/* stylelint-enable */
