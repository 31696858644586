.file-upload {
    &__button {
        font-weight: 900;
        font-size: rem-calc(16);
        text-transform: uppercase;
        text-decoration: underline;
        cursor: pointer;

        svg {
            display: inline-block;
            fill: map-deep-get($colors, "white", "default");
            height: 1em;
            width: auto;
            margin-right: 0.5em;
            vertical-align: middle;
            transition: $transition-default;
        }

        &:hover {
            svg {
                fill: map-deep-get($colors, "brand", "primary");
                margin-left: 0.4em;
                margin-right: 0.1em;
            }
        }
    }

    &__input {
        display: none;
    }

    &__files {
        margin-bottom: rem-calc(24);
    }

    &__file {
        transition: background-color $transition-default-smoothing $transition-default-time;
        display: inline-flex;
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "black", "default");
        margin: 0 0.5em 0.5em 0;
        $file: &;

        &__icon {
            padding: 0.5em 0 0 0.5em;

            svg {
                width: 1em;
                height: 1em;
            }

            &--stroke svg {
                stroke: map-deep-get($colors, "brand", "primary");
            }

            &--fill svg {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }

        &__label {
            padding: 0.5em;
        }

        &__remove {
            transition: background-color $transition-default-smoothing $transition-default-time;
            padding: 0 0.5em;
            display: inline-flex;
            align-items: center;
            background: map-deep-get($colors, "gray", "light");
            cursor: pointer;

            svg {
                stroke: map-deep-get($colors, "black", "default");
            }
        }

        &:hover {
            background: map-deep-get($colors, "gray", "light");

            #{$file + "__remove"} {
                background: darken(map-deep-get($colors, "gray", "light"), 5%);
            }
        }
    }
}