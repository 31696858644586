.job-filter {
    align-items: center;
    background-color: #F2F2FA;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(md) {
        flex-direction: row;
        gap: 0;
    }

    &__textfield {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: .75rem 1.5rem;
        position: relative;
        text-align: center;
        width: 100%;

        @include media-breakpoint-up(md) {
            padding: 0;
            width: 50%;
        }

        &::after {
            background-color: #454547;
            bottom: 0;
            content: "";
            height: .0625rem;
            position: absolute;
            width: 90%;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }

        input {
            border: none;
            color: #454547;
            font-size: 1.125rem;
            font-weight: $font-weight-light;
            line-height: 1.5;
            position: relative;
            text-align: start;

            @include media-breakpoint-up(md) {
                border-right: .0625rem solid #454547;
                margin-left: 10%;
            }
        }
    }

    &__select {
        align-items: center;
        display: flex;
        padding: .75rem 1.5rem;
        width: 100%;

        @include media-breakpoint-up(md) {
            padding: 0;
            width: 50%;
        }

        &::after {
            background-image: url("../../../images/chevron-down.svg");
            content: "";
            display: block;
            height: 1.375rem;
            margin-right: 0;
            position: relative;
            top: .125rem;
            width: 1.375rem;

            @include media-breakpoint-up(md) {
                margin-right: 1rem;
            }
        }

        select {
            background-color: transparent;
            border: none;
            color: #454547;
            font-size: 1.125rem;
            font-weight: $font-weight-light;
            line-height: 1.5;
            text-align: start;

            @include media-breakpoint-up(lg) {
                text-align: center;
            }
        }
    }

    &__search {
        background-color: #03C700;
        border: none;
        display: flex;
        font-weight: 500;
        gap: 1rem;
        height: 100%;
        justify-content: space-between;
        line-height: 1.3;
        padding: 1.5rem 1.5rem 1.5em 2.5rem;
        transition: $transition-default;
        width: 100%;

        @include media-breakpoint-up(md) {
            justify-content: center;
            padding: 2.125rem 3.3125rem;
            width: auto;
        }

        &:hover,
        &:focus-visible {
            background-color: #009400;
            cursor: pointer;
        }
    }


    &__reset-filter {
        text-align: right;
    }
}
