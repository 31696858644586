.scroll-down {
    -webkit-font-smoothing: antialiased;
    align-items: center;
    bottom: 0;
    display: flex;
    font-weight: 900;
    left: 0;
    position: absolute;
    text-decoration: underline;
    text-transform: uppercase;
    z-index: 2;

    svg {
        height: 1.2em;
        fill: map-deep-get($colors, "brand", "primary");
        margin-right: 0.75em;
        width: 1em;
        transition: $transition-default;
    }

    &:hover {
        color: map-deep-get($colors, "brand", "primary");

        svg {
            margin: 0.2em 0.75em -0.2em 0;
        }
    }

    &--home {
        color: map-deep-get($colors, "black", "default");

        svg {
            fill: map-deep-get($colors, "black", "default");
        }

        &:hover {
            color: map-deep-get($colors, "black", "default");
        }
    }
}
