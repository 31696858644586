.block {
    position: relative;
    width: 100%;
    display: flex;
    $block: &;

    &:hover {
        text-decoration: none;

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            #{$block + "__image"} {
                // b-lazy
                /* stylelint-disable */
                &.b-lazy.b-loaded {
                    opacity: 0.8;
                }
                /* stylelint-enable */
            }

            #{$block + "__button"} {
                @extend .button--primary:hover;
            }
        }
    }

    &__image-wrapper {
        position: relative;
        width: 100%;
        background: map-deep-get($colors, "black", "default");
        z-index: 1;

        @include media-breakpoint-up(md) {
            width: 70%;
        }

        @include media-breakpoint-up(lg) {
            width: 55%;
        }

        &--mobile {
            height: 60vw;
            margin: 15px -25px;
            width: auto;
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
        object-position: top;
        width: 100%;

        @include media-breakpoint-up(md) {
            object-position: center;
        }

        // b-lazy
        /* stylelint-disable */
        &.b-lazy.b-loaded {
            opacity: 0.6;
        }
        /* stylelint-enable */
    }

    &__content-wrapper {
        align-items: center;
        background: map-deep-get($colors, "brand", "secondary");
        display: flex;
        position: relative;
        width: 100%;
        padding: 25px;

        @include media-breakpoint-up(md) {
            margin: 60px 0;
            padding: 50px;
            width: 75%;
        }

        @include media-breakpoint-up(lg) {
            padding: 4rem;
            width: 60%;
        }

        @include media-breakpoint-up(xxl) {
            padding: 6rem;
        }
    }

    &__content {
        position: relative;
        color: map-deep-get($colors, "white", "default");
        z-index: 2;

        // markdown editor
        /* stylelint-disable */
        strong {
            font-weight: inherit;
            color: map-deep-get($colors, "brand", "primary");
        }
        /* stylelint-enable */
    }

    &__title {
        /* stylelint-disable */
        h2, h3, h4, h5 {
            color: map-deep-get($colors, "white", "default");
            font-size: 2.5rem;
            margin: 0 0 1.5rem;

            @include media-breakpoint-up(md) {
                font-size: 5rem;
            }
        }
        /* stylelint-enable */
    }

    &__description {
        p:last-child {
            margin-bottom: 0;
        }
    }

    &--right {
        #{$block + "__image-wrapper"} {
            order: 2;
        }

        #{$block + "__content-wrapper"} {
            @include media-breakpoint-up(md) {
                margin-right: -25%;
            }

            @include media-breakpoint-up(lg) {
                margin-right: -10%;
            }
        }
    }

    &--left {
        #{$block + "__content-wrapper"} {
            @include media-breakpoint-up(md) {
                margin-left: -25%;
            }

            @include media-breakpoint-up(lg) {
                margin-left: -10%;
            }
        }
    }
}
