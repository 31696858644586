/* stylelint-disable */
.video-js {
    overflow: hidden;

    .vjs-tech {
        bottom: 0;
        height: auto;
        min-height: 100%;
        min-width: 100%;
        width: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
    }

    &.video--playsinline {
        transition: opacity $transition-default-smoothing $transition-default-time;
        opacity: 0;

        &.vjs-has-started.vjs-fullscreen,
        &.vjs-has-started.vjs-paused.vjs-scrubbing {
            opacity: 1;
        }
    }

    &.vjs-fullscreen .vjs-tech {
        height: 100%;
        width: 100%;
        position: relative;
        transform: none;
        left: auto;
        top: auto;
        min-height: auto;
        min-width: auto;
        object-fit: contain;
    }
}
/* stylelint-enable */
