.header {
    // z-index is om te zorgen dat deze boven de barba laag zit. Hierdoor wordt voor het oog alleen de content vervangen
    background: map-deep-get($colors, "white", "default");
    left: 0;
    overflow: hidden;
    padding: 5px 0;
    position: fixed;
    right: 0;
    top: 0;
    transition: $transition-default;
    width: 100%;
    z-index: 99;
    $header: &;

    @include media-breakpoint-up(md) {
        background-color: transparent;
        overflow: visible;
        padding: 15px 0;
    }

    &--scrolled {
        background: map-deep-get($colors, "white", "default");
        padding: 5px 0;

        #{$header}__video-trigger {
            display: none;
        }

        #{$header}__hero-button {
            opacity: 1;
            pointer-events: auto;
            //display: inline;
        }
    }

    &--expanded {
        background: map-deep-get($colors, "white", "default");
        height: 100%;
        overflow: hidden;
        overflow-y: auto;
    }

    &__buttons {
        align-items: center;
        display: flex;
        gap: 1rem;
        justify-content: center;
        pointer-events: all;
    }

    &__home-button {
        &--desktop {
            display: none;

            @include media-breakpoint-up(md) {
                display: inline-flex;
            }

            svg {
                height: 1.375rem;
                // Temporary
                margin-top: -10px;
                width: 1.375rem;
            }
        }

        &--mobile {
            display: inline-flex;

            @include media-breakpoint-up(md) {
                display: none;
            }
        }
    }

    &__hero-button {
        transition: $transition-default;
        display: none;

        @include media-breakpoint-up(md) {
            opacity: 1;
            pointer-events: auto;
            display: inline;
        }
    }

    &__link {
        svg {
            height: rem-calc(22);
            width: rem-calc(22);
        }

        &--back {
            margin-right: 0.75em;
        }

        &--video {
            margin-left: 0.75em;
        }
    }

    &__video-trigger {
        appearance: none;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;

        &:focus {
            outline: none;
        }

        svg {
            fill: map-deep-get($colors, "brand", "primary");
            transition: $transition-default;
        }

        &:hover {
            svg {
                fill: darken(map-deep-get($colors, "brand", "primary"), 10%);
            }
        }
    }

    &__menu-toggle {
        float: right;
        position: relative;
    }

    &__language {
        -webkit-font-smoothing: antialiased;
        color: map-deep-get($colors, "brand", "primary");
        font-size: rem-calc(16);
        font-weight: 900;
        text-transform: uppercase;
        transition: $transition-default;

        @include media-breakpoint-up(md) {
            margin-left: 1.25em;
        }

        &:hover {
            color: map-deep-get($colors, "white", "default");
            text-decoration: none;
        }
    }
}
