.button {
    align-items: center;
    background-color: map-deep-get($colors, "gray", "dark");
    border: .25rem solid transparent;
    border-radius: 1.875rem;
    color: map-deep-get($colors, "white", "default");
    cursor: pointer;
    display: inline-flex;
    font-family: $font-family-special;
    font-size: 1rem;
    font-weight: 900;
    letter-spacing: .05em;
    line-height: 1;
    padding: .8125rem 1rem 0.5625rem;
    transition: $transition-default;
    text-transform: uppercase;
    text-align: center;

    @include media-breakpoint-up(sm) {
        font-size: 1.25rem;
    }

    &:hover {
        text-decoration: none;
    }

    svg {
        width: 1.25em;
        height: 1.65em;
        margin-right: 0.5em;
        vertical-align: middle;
        fill: map-deep-get($colors, "black", "default");
        transition: $transition-default;
        margin-top: -0.225em;
        margin-bottom: -0.225em;
    }

    &--wide {
        width: 100%;
        justify-content: center;
    }

    &--large {
        @include media-breakpoint-up(md) {
            padding-right: 50px;
        }

        @include media-breakpoint-up(lg) {
            padding-right: 75px;
        }

        @include media-breakpoint-up(xl) {
            padding-right: 100px;
        }
    }

    &--arrow::before {
        content: url("../images/template/icons/arrow-right.svg");
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
        vertical-align: middle;
    }

    &--hero::before {
        content: url("../images/template/icons/heldicon.svg");
        width: 1.2em;
        height: 1.65em;
        margin-right: .5em;
        vertical-align: middle;
    }

    &--primary {
        background-color: map-deep-get($colors, "brand", "primary");
        color: map-deep-get($colors, "black", "default");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
                color: map-deep-get($colors, "black", "default");
            }
        }
    }

    &--black {
        background: map-deep-get($colors, "black", "default");
        color: map-deep-get($colors, "white", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background-color: lighten(map-deep-get($colors, "black", "default"), 10%);
            }
        }

        svg {
            fill: map-deep-get($colors, "brand", "primary");
        }
    }

    &--white {
        background: map-deep-get($colors, "white", "default");
        color: map-deep-get($colors, "brand", "primary");

        @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
            &:hover {
                background: map-deep-get($colors, "gray", "light");
            }
        }
    }

    &--ghost {
        background: none;

        &.button--primary {
            border: 4px solid map-deep-get($colors, "brand", "primary");
            color: map-deep-get($colors, "brand", "primary");

            @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
                &:hover {
                    border-color: darken(map-deep-get($colors, "brand", "primary"), 10%);
                    color: map-deep-get($colors, "black", "default");

                    svg {
                        fill: map-deep-get($colors, "black", "default");
                    }
                }
            }

            svg {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }

        &.button--white {
            border: 4px solid map-deep-get($colors, "white", "default");
            color: map-deep-get($colors, "white", "default");

            @media (pointer: fine), (min-width: map_get($grid-breakpoints, "xl")) {
                &:hover {
                    border-color: map-deep-get($colors, "gray", "light");
                    color: map-deep-get($colors, "black", "default");

                    svg {
                        fill: map-deep-get($colors, "black", "default");
                    }
                }
            }

            svg {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        &.button--black {
            border: 4px solid map-deep-get($colors, "black", "default");
            color: map-deep-get($colors, "black", "default");

            svg {
                fill: map-deep-get($colors, "black", "default");
            }
        }
    }

    &--static {
        pointer-events: none;
    }
}
