.job-specs {
    $block: &;
    background: map-deep-get($colors, "brand", "secondary");
    padding: 3.75rem 0;
    z-index: 25;
    position: relative;

    @include media-breakpoint-up(md) {
        padding: 5.625rem 0 7.5rem;
    }

    &__title {
        color: map-deep-get($colors, "brand", "primary");
    }

    &__content {
        line-height: 1.5;

        &--right {
            /* stylelint-disable */
            ul + h3 {
                margin-top: 2.75rem;
            }
            /* stylelint-enable */
        }

        /* stylelint-disable */
        h1, h2, h3, h5, h6, p {
            color: map-deep-get($colors, "white", "default");
        }
        /* stylelint-enable */

        /* stylelint-disable */
        h3 {
            font-size: 1.25rem;

            @include media-breakpoint-up(md) {
                font-size: 1.5rem;
            }

            @include media-breakpoint-up(xl) {
                font-size: 2rem;
            }
        }
        /* stylelint-enable */

        /* stylelint-disable */
        ul {
            margin: 1.25rem 0 1.25rem 0;

            li {
                color: map-deep-get($colors, "white", "default");
                line-height: 1.5;
                margin-bottom: .25rem;
                margin-top: .25rem;
                padding-left: 1.25rem;
                position: relative;

                &::before {
                    background: map-deep-get($colors, "white", "default");
                    border-radius: 50%;
                    content: "";
                    height: 0.375rem;
                    left: 0;
                    position: absolute;
                    top: .75rem;
                    transform: translateY(-50%);
                    width: 0.375rem;
                }
            }
        }
        /* stylelint-enable */
    }
}
