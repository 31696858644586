.text-media {
    position: relative;
    height: 0;
    padding-top: aspect-ratio(1340, 630);
    margin-bottom: 3rem;
    margin-top: 3rem;
    transition: $transition-default;
    $elm: &;

    @include media-breakpoint-up(md) {
        margin-bottom: 4rem;
        margin-top: 4rem;
    }

    &--mt0 {
        margin-top: 0;
    }

    &--mb0 {
        margin-bottom: 0;
    }

    &__image-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__image {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    &__video {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
        cursor: pointer;

        &:hover {
            + #{$elm}__video-trigger svg {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
    }

    &__video-trigger {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        appearance: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:focus {
            outline: none;
        }

        svg {
            transition: fill $transition-default-smoothing $transition-default-time;
            fill: map-deep-get($colors, "black", "default");
            height: rem-calc(50);
            width: rem-calc(50);

            @include media-breakpoint-up(md) {
                height: rem-calc(100);
                width: rem-calc(100);
            }

            @include media-breakpoint-up(lg) {
                height: rem-calc(145);
                width: rem-calc(145);
            }
        }
    }

    &--aspect-video {
        padding-top: aspect-ratio(1920, 810);

        /* stylelint-disable */
            .video-js .vjs-tech {
                height: 100%;
            }
        /* stylelint-enable */

        #{$elm}__video-trigger {
            opacity: 0;
            pointer-events: none;
        }

        #{$elm}__video {
            opacity: 1;
        }
    }
}
