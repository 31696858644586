.company-card {
    $block: &;
    background: map-deep-get($colors, "white", "default");
    border: solid .0625rem #dedee5;
    border-radius: $border-radius;
    color: inherit;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 1.75rem 1.25rem;
    position: relative;
    text-decoration: none;
    transition: $transition-default;
    width: 100%;

    @include media-breakpoint-up(sm) {
        padding: 2rem 1.75rem;
    }

    @include media-breakpoint-up(md) {
        padding: 2.875rem 2.625rem;
    }

    &:hover,
    &:focus-visible {
        color: inherit;
        text-decoration: none;
        transform: scale3d(1.025, 1.025, 1.025);

        #{$block}__image {
            filter: none;
        }
    }

    &__image {
        /* stylelint-disable */
        aspect-ratio: 1/1;
        /* stylelint-enable */
        filter: grayscale(100%);
        height: auto;
        object-fit: contain;
        max-width: 100%;
        width: 100%;
    }
}
