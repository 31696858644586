.section {
    $block: &;
    position: relative;
    margin: 0 0 60px;

    @include media-breakpoint-up(md) {
        margin: 110px 0;
    }

    &--mb0 {
        margin-bottom: 0;
    }

    &--mt0 {
        margin-top: 0;
    }

    &--companies {
        display: flex;
        flex-direction: column;
        gap: 1.5rem 0;

        @include media-breakpoint-up(lg) {
            gap: 3rem 0;
        }
    }

    &--after-bg {
        @include media-breakpoint-up(xl) {
            margin-top: 0;
        }

        &::after {
            @include media-breakpoint-up(xl) {
                background: map-deep-get($colors, "brand", "secondary");
                content: "";
                height: 25rem;
                left: 0;
                position: absolute;
                right: 0;
                top: 0;
                z-index: -1;
            }
        }
    }

    &--slider {
        margin: 0 0 6.625rem;

        @include media-breakpoint-up(sm) {
            margin: 0 0 8rem;
        }

        @include media-breakpoint-up(lg) {
            margin: 0 0 3.75rem;
        }
    }

    @at-root .section--after-bg & {
        margin-top: 3rem;

        @include media-breakpoint-up(md) {
            margin-top: 4rem;
        }
    }

    &__subtitle {
        color: #454547;
        line-height: 1.2;
        opacity: .7;

        #{$block}--companies & {
            margin-bottom: 0;
        }
    }

    &__heading {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        gap: 1rem 0;
        justify-content: space-between;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            gap: 1.5rem 0;
        }
    }

    &__headings {
        display: flex;
        flex-direction: column;
    }

    &__companies {
        display: flex;
        flex-flow: row wrap;
        gap: .25rem;
    }

    &__company {
        display: flex;
        flex: 0 0 calc((100% / 2) - .25rem);
        max-width: calc((100% / 2) - .25rem);

        @include media-breakpoint-up(sm) {
            flex: 0 0 calc((100% / 3) - .25rem);
            max-width: calc((100% / 3) - .25rem);
        }

        @include media-breakpoint-up(md) {
            flex: 0 0 calc((100% / 4) - .25rem);
            max-width: calc((100% / 4) - .25rem);
        }

        @include media-breakpoint-up(xl) {
            flex: 0 0 calc((100% / 6) - .25rem);
            max-width: calc((100% / 6) - .25rem);
        }
    }
}
