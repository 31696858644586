.landing {
    &__heading {
        padding: 2rem 0 8rem;
    }

    &__text {
        padding-top: 125px;
    }

    &__button {
        margin: 5px 0;
    }
}
