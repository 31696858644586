.certificates {
    list-style: none;
    margin: 1em -10px 0.2em;

    @include media-breakpoint-up(md) {
        margin: 1em -20px 0.02em;
    }

    @include media-breakpoint-up(lg) {
        margin: 1em -25px 0.02em;
    }

    @include media-breakpoint-up(xl) {
        margin: 1em -35px 0.02em;
    }

    &__item {
        display: block;
        margin-bottom: 1em;
        margin-top: 1em;
        padding-left: 10px;
        padding-right: 10px;
        font-size: rem-calc(16);

        @include media-breakpoint-up(md) {
            display: inline-flex;
            align-items: center;
            padding-left: 20px;
            padding-right: 20px;
        }

        @include media-breakpoint-up(lg) {
            padding-left: 25px;
            padding-right: 25px;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 35px;
            padding-right: 35px;
        }
    }
}