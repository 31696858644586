.hero-slider {
    pointer-events: auto;
    height: 100%;

    /* stylelint-disable */
    .flickity-viewport {
        overflow: visible;
    }

    .flickity-slider {
        transform: none!important;
        z-index: 1;
    }

    &__slide {
        height: 100%;
        position: absolute;
        width: 0;
        opacity: 0;
        left: 50% !important;
        transition: all ease-in 0.25s;
        z-index: -1;

        &.is-selected,
        &--pre,
        &--post {
            width: 33%;;
            opacity: 0.5;

            .hero-slide__link {
                pointer-events: auto;
                height: 100%;
                width: 200%;

                @include media-breakpoint-up(md) {
                    width: 250%;
                }
            }
        }

        &--pre {
            left: 66% !important;
        }

        &--post {
            left: 0 !important;
        }
    }
    /* stylelint-enable */

    &__navigation {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: 15;
        display: flex;
        justify-content: space-between;
    }

    &__navigation-item {
        pointer-events: auto;
        border: 0.25rem solid map-deep-get($colors, "brand", "primary");
        border-radius: 50%;
        background: map-deep-get($colors, "brand", "primary");
        padding: 0.5rem;
        transition: $transition-default;
        line-height: 0;

        @include media-breakpoint-up(md) {
            padding: 1.219rem;
        }

        svg {
            transition: $transition-default;
            fill: map-deep-get($colors, "black", "default");
            height: 1rem;
            width: 1rem;
        }

        &:focus {
            outline: none;
        }

        &:hover {
            background: none;
            border-color: map-deep-get($colors, "white", "default");

            svg {
                fill: map-deep-get($colors, "brand", "primary");
            }
        }
    }
}