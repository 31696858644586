.hero-slide {
    $elm: &;

    &__link {
        display: block;
        height: 0;
        transition: all ease-in-out 0.2s;
        width: 0;
        margin-left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
    }

    &__caption {
        color: map-deep-get($colors, "white", "default");
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity ease-in-out 0.2s 0.15s;
        text-decoration: underline;
        text-align: center;
        line-height: 1.275;
    }

    &__image {
        object-fit: contain;
        object-position: top;
        height: 100%;
        width: 100%;
        transform-origin: top center;
        transition: all ease-in-out 0.2s;
    }

    /* stylelint-disable */
    &.is-selected {
        opacity: 1;
        z-index: 1;
        left: 33% !important;

        #{$elm}__caption {
            opacity: 1;
        }

        #{$elm}__link {
            width: 300%;
        }
    }
    /* stylelint-enable */
}
