.jobs-overview {
    &__row {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 4.375rem;
    }

    &__form {
        display: contents;
    }
}
