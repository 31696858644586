.hero {
    $block: &;
    background: map-deep-get($colors, "black", "default");
    height: 100vh;
    padding-top: 15px;
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }

    &:not(.hero--hero, .hero--text) {
        height: calc(100vh - var(--hH));

        @include media-breakpoint-up(md) {
            height: calc(var(--vh));
        }
    }

    &--home {
        margin-bottom: calc(10vw + 60px);

        #{$block}__video {
            opacity: 0.6;
        }
    }

    &--text {
        background-color: map-deep-get($colors, "brand", "secondary");
        height: auto;
        z-index: auto;
    }

    &--hero {
        @media (min-width: map_get($grid-breakpoints, "lg")) and (min-height: $v-screen-md) {
            max-height: 80vh;
        }
    }

    &--center {
        text-align: center;
    }

    &__video {
        bottom: 0;
        left: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 0;
    }

    &__video-poster {
        height: 100%;
        object-fit: cover;
        width: 100%;
        opacity: .6;
    }

    &__carousel {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
        top: 25%;
        z-index: 1;
        pointer-events: none;
    }

    &__edge {
        bottom: 0;
        left: 0;
        position: absolute;
        right: 0;
    }

    &__edge-svg {
        svg {
            margin-bottom: -1px;
            width: 100%;

            /* stylelint-disable */
            path {
                #{$block}--hero & {
                    fill: map-deep-get($colors, "brand", "secondary");
                }
            }
            /* stylelint-enable */
        }
    }

    &__logo-wrapper {
        position: relative;
        z-index: 15;
    }

    &__logo {
        display: block;
        height: auto;
        margin: 0 auto;
        padding-top: 5px;
        padding-bottom: 5px;
        transition: $transition-default;
        max-width: 12.5rem;

        @include media-breakpoint-up(lg) {
            padding-top: 0;
            padding-bottom: 0;
        }

        &--home {
            @include media-breakpoint-up(xxl) {
                max-width: 16.25rem;
            }
        }

        // svg class overrulen
        /* stylelint-disable */
        svg {
            height: auto;
            width: 100%;

            .st0 {
                fill: map-deep-get($colors, "white", "default");
            }
        }

        &--black {
            svg .st0 {
                fill: map-deep-get($colors, "black", "default");
            }
        }

        /* stylelint-enable */
    }

    &__image-wrapper {
        background-color: map-deep-get($colors, "black", "default");
        bottom: 0;
        left: 0;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;

        &::before {
            content: "";
            background: linear-gradient(0deg, rgba(0, 0, 0, 0) 85.96%, map-deep-get($colors, "black", "default") 100%);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__image {
        height: 100%;
        object-fit: cover;
        opacity: 0.6;
        width: 100%;
        z-index: -1;
        position: relative;
    }

    &__content {
        position: absolute;
        top: 20%;
        right: 0;
        bottom: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        text-align: center;
        z-index: 1;

        @at-root body:not(.ie) & {
            display: flex;
        }

        @at-root body.ie & {
            top: 30%;
        }

        @include media-breakpoint-up(lg) {
            text-align: left;
        }

        #{$block}--hero & {
            pointer-events: none;
        }

        /* stylelint-disable */
        p {
            font-size: larger;
            font-weight: bolder;
        }
        /* stylelint-enable */

        @media (max-height: $v-screen-md) and (max-width: map_get($grid-breakpoints, "md")) {
            top: 0;
        }
        @media (max-height: $v-screen-lg) {
            h1 {
                font-size: 4rem;
            }

            p {
                font-size: 1.4rem;
            }
        }
    }

    &__job-title {
        color: map-deep-get($colors, "brand", "primary");
    }

    &__job-info {
        color: map-deep-get($colors, "white", "default");
        font-size: rem-calc(20);
        line-height: 1.6em;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(30);
        }
    }

    &__video-trigger {
        cursor: pointer;
    }

    &__video-trigger:not(.hero__video) {
        appearance: none;
        background: none;
        border: none;
        height: rem-calc(80);
        width: rem-calc(80);
        margin-top: 50px;

        @include media-breakpoint-up(md) {
            height: rem-calc(100);
            width: rem-calc(100);
        }

        @include media-breakpoint-up(lg) {
            height: rem-calc(120);
            width: rem-calc(120);
        }

        svg {
            transition: fill $transition-default-smoothing $transition-default-time;
            fill: map-deep-get($colors, "brand", "primary");
            height: 100%;
            width: 100%;
        }

        &:hover {
            svg {
                fill: darken(map-deep-get($colors, "brand", "primary"), 10%);
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-bottom: 7rem;

        @include media-breakpoint-up(sm) {
            padding-bottom: 8rem;
        }

        @include media-breakpoint-up(lg) {
            gap: 5rem;
            padding-bottom: 10rem;
        }

        @include media-breakpoint-up(xl) {
            gap: 9.375rem;
        }

        #{$block}--padding-small & {
            padding-bottom: 5rem;

            @include media-breakpoint-up(sm) {
                padding-bottom: 6rem;
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: 7.5rem;
            }
        }

        #{$block}--after-bg & {
            padding-bottom: 3rem;

            @include media-breakpoint-up(sm) {
                padding-bottom: 4rem;
            }

            @include media-breakpoint-up(xl) {
                padding-bottom: 7.5rem;
            }
        }

        #{$block}--company & {
            padding-bottom: 8rem;

            @include media-breakpoint-up(sm) {
                padding-bottom: 10rem;
            }

            @include media-breakpoint-up(md) {
                padding-bottom: 12rem;
            }

            @include media-breakpoint-up(lg) {
                padding-bottom: 13rem;
            }
        }
    }

    &__title {
        #{$block}--text & {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__text {
        #{$block}--text & {
            color: map-deep-get($colors, "white", "default");
        }
    }

    &__company-image-wrapper {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 25%);
    }

    &__company-image {
        /* stylelint-disable */
        aspect-ratio: 1/1;
        /* stylelint-enable */
        background: map-deep-get($colors, "white", "default");
        border-radius: 50%;
        height: auto;
        max-width: 8rem;

        @include media-breakpoint-up(md) {
            max-width: 10.25rem;
        }
    }
}
