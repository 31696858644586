.footer {
    position: relative;
    background-color: map-deep-get($colors, "white", "default");
    color: map-deep-get($colors, "black", "default");
    z-index: 15;
    flex: 1 1 100%;

    a:not(.button) {
        color: map-deep-get($colors, "black", "default");
    }

    &__edge {
        position: relative;
        width: 100%;
        padding-top: 6.94%;
        margin-top: 20px;

        svg {
            position: absolute;
            top: 1px;
            right: 0;
            bottom: 0;
            left: 0;
            margin-bottom: -1px;
            height: 100%;
            width: 100%;
            vertical-align: bottom;

            // svg
            /* stylelint-disable */
            path {
                fill: map-deep-get($colors, "gray", "light");
            }
            /* stylelint-enable */
        }
    }

    &__top {
        -webkit-font-smoothing: none;
        background-color: map-deep-get($colors, "gray", "light");
        padding: 2.5rem 0;
        position: relative;

        @include media-breakpoint-up(lg) {
            padding: 3.125rem 0;
        }

        /* stylelint-disable */
        .rich-text-area {
            p:last-child {
                margin-bottom: 0;
            }
        }
        /* stylelint-enable */
    }

    &__image {
        position: absolute;
        right: 0;
        bottom: -50px;
        width: 80%;
    }

    &__bottom {
        padding-top: 15px;
        padding-bottom: 15px;

        @include media-breakpoint-up(md) {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        /* stylelint-disable */
        a:not(.button) {
            color: map-deep-get($colors, "black", "default");
        }
        /* stylelint-enable */
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
    }

    &__list-item {
        width: 100%;
        flex: 0 0 100%;
        text-align: center;
        font-size: rem-calc(14);
        font-weight: 800;
        color: map-deep-get($colors, "black", "default");
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        margin-bottom: 0.5em;
        text-transform: uppercase;

        @include media-breakpoint-up(md) {
            font-size: rem-calc(12);
            margin-bottom: 0;
            display: inline-flex;
            width: auto;
            flex: 0 0 auto;
            text-align: left;
            padding-right: 10px;
            align-items: center;

            &::after {
                content: "";
                width: 0.25em;
                height: 0.25em;
                background: map-deep-get($colors, "black", "default");
                border-radius: 50%;
                display: inline-block;
                vertical-align: middle;
                margin-left: 10px;
            }

            &:last-child::after {
                content: none;
            }
        }
    }

    &__item {
        color: inherit;
        text-decoration: underline;

        &--webnl {
            margin: 0 5px;
        }

        &:hover {
            text-decoration: none;
        }
    }

    &__initiative {
        text-align: center;
        padding-top: 25px;

        &__logo {
            display: inline-block;
            margin: 25px 0;
        }

        &__text {
            font-size: rem-calc(12);
            text-transform: uppercase;
            font-weight: 800;
            letter-spacing: 0.07em;

            // webkey content
            /* stylelint-disable */
            a {
                text-decoration: underline;
            }
            /* stylelint-enable */
        }
    }

    /* stylelint-disable */
    .rich-text-area {
        h1, h2, h3, h4 {
            margin-bottom: 2rem;
        }

        p {
            margin-bottom: 2rem;
        }
    }
    /* stylelint-enable */
}
